<template>
    <v-container fluid class="pa-0 pa-md-3">
		<v-card class="elevation-0">
			<QDataTableHeader color="q_leads_1">
				<template #left>
					<div class="d-flex align-center">
						<p class="q-display-3 mb-0 white--text">{{ title }}</p>
						<v-btn dark icon class="ml-2" @click="refreshData">
							<v-icon>fas fa-redo-alt</v-icon>
						</v-btn>
					</div>
				</template>
				<template #right>
					<div class="d-flex justify-end" style="width:100%">
						<v-text-field
							v-model="search"
							append-icon="icon-q-search"
							label="Search Carrier"
							dense dark single-line hide-details clearable
							:style="$vuetify.breakpoint.xs ? {} : { maxWidth: '30rem' }"
						/>
					</div>
				</template>
			</QDataTableHeader>

			<div class="pt-1" style="height:4px">
				<v-progress-linear v-if="isLoading" color="q_leads_1" indeterminate />
			</div>

			<v-row justify="space-between" class="my-3 mx-0">
				<v-btn text small color="q_leads_4" @click="toggleFilters">
					<v-icon small left>fas fa-filter</v-icon> Filters
				</v-btn>
			</v-row>

			<v-row v-show="showFilters" justify="center" class="mx-0">
				<QFilters
					ref="filters"
					v-model="filters"
					:keys="filterKeys"
					:available-filters="filtersOptions"
					:hide-button="true"
					alignment="center"
				/>
			</v-row>

			<v-row v-if="!error" class="mt-4 mx-0">
				<v-col cols="12">
					<p class="mb-0 body-1"><strong>Note:</strong> A checkmark&nbsp;<v-icon color="q_leads_1">fas fa-check-circle</v-icon> indicates carrier data for pending cases is received daily. For carriers where pending case data is not received, kindly click on the carrier name to access the respective agent portal. Use the search function to quickly find a carrier.</p>
				</v-col>
			</v-row>

			<v-row class="mt-4 mx-0">
				<template v-if="!isLoading && error">
					<v-col cols="12">
						<v-alert border="left" outlined type="error">
							<p class="mb-0">An error occurred while loading data. Please reload the data.</p>
						</v-alert>
					</v-col>
				</template>

				<template v-else-if="!isLoading && !carriers.length">
					<v-col cols="12" class="d-flex justify-center font-weight-bold">No results were found for the selected filters</v-col>
				</template>

				<template v-else>
					<v-col v-for="carrier in carriers" :key="carrier.ID" cols="6" sm="4" md="3" lg="2">
						<div class="carrier-card">
							<div class="carrier-card__name">
								<a v-if="carrier.AgentLoginURL" :href="carrier.AgentLoginURL" target="_blank">{{ carrier.CarrierName }}</a>
								<span v-else>{{ carrier.CarrierName }}</span>
							</div>
							<div class="carrier-card__checkmark">
								<v-icon v-if="carrier.HasPPFeed" color="q_leads_1">fas fa-check-circle</v-icon>
							</div>
						</div>
					</v-col>
				</template>
			</v-row>
		</v-card>
    </v-container>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import QFilters from '@/components/utils/QFilters.vue'

const ALL_OPTION = '-All-'

export default {
	components: {
        QDataTableHeader,
		QFilters,
    },
	props: {
		title: {
            type: String,
            default: 'Carriers',
        },
		agent: {
			type: Object,
			default: null,
		},
	},
    data () {
        return {
            allCarriersLoading: false,
            agentCarriersLoading: false,
            search: null,
            showFilters: false,
            filters: {
				Carriers: 'My Carriers',
				DataStatus: ALL_OPTION,
			},
            filtersOptions: {
				Carriers: ['My Carriers', ALL_OPTION],
				DataStatus: [ALL_OPTION, 'Data Received', 'Not Received'],
			},
			allCarriers: [],
			agentCarriers: [],
			error: false,
        }
    },
    mounted () {
        this.refreshData()
    },
    computed: {
        filterKeys () {
            return ['Carriers', 'DataStatus']
        },
		carriersComputed () {
			if (!this.allCarriers.length) { return [] }
			if (!this.agentCarriers.length) { return this.allCarriers }
			return this.allCarriers.map(item => ({
				...item,
				HasActiveWritingNumber: this.agentCarriers.some(({ CarrierID }) => CarrierID === item.ID),
			}))
		},
		carriersFiltered () {
			return this.carriersComputed
				.filter(item => {
					if (this.filters.Carriers === ALL_OPTION) { return true }
					if (this.filters.Carriers === 'My Carriers') {
						return item.HasActiveWritingNumber === true
					}
					return false
				})
				.filter(item => {
					if (this.filters.DataStatus === ALL_OPTION) { return true }
					if (this.filters.DataStatus === 'Data Received') {
						return item.HasPPFeed === 1
					}
					if (this.filters.DataStatus === 'Not Received') {
						return item.HasPPFeed === 0
					}
					return false
				})
				.sort((a, b) => a.CarrierName.localeCompare(b.CarrierName))
		},
		carriers () {
			if (!this.search) { return this.carriersFiltered }
			const searchToLowerCase = this.search.toLowerCase()
			return this.carriersFiltered.filter(carrier => carrier.CarrierName.toLowerCase().includes(searchToLowerCase))
		},
        isLoading () {
            return this.allCarriersLoading || this.agentCarriersLoading
        },
    },
    methods: {
		toggleFilters () {
            this.showFilters = !this.showFilters
        },
		async refreshData () {
			this.error = false
			this.loadAllCarriers()
			this.loadAgentCarriers()
		},
        async loadAllCarriers () {
            this.allCarriersLoading = true
			this.allCarriers = []
            try {
                const res = await QuilityAPI.getCarriers({ itemsPerPage: 'all' })
				this.allCarriers = res.data
			} catch (err) {
				this.error = true
				console.error(err)
				this.showError('Could not load carriers.<br>', err.message)
            } finally {
				this.allCarriersLoading = false
			}
        },
        async loadAgentCarriers () {
            this.agentCarriersLoading = true
            this.agentCarriers = []
            try {
                // const res = await QuilityAPI.getActiveCarrierWritingNumbers(this.agent.AgentCode)
                // const res = await QuilityAPI.getCarrierWritingNumbers(this.agent.AgentCode)
				const res = await QuilityAPI.getAgentStateless(this.agent.AgentCode, ['WritingNumbers'])
				this.agentCarriers = res?.WritingNumbers || []
			} catch (err) {
				this.error = true
				console.error(err)
				this.showError('Could not load active carrier writing numbers.<br>', err.message)
            } finally {
				this.agentCarriersLoading = false
			}
        },
    },
    watch: {
        'agent.AgentCode' () {
			this.refreshData()
        },
		showFilters (newV) {
            this.$refs.filters.show = newV
        },
    },
}
</script>

<style lang="scss" scoped>
.carrier-card {
	padding: 0.2rem 0.5rem;
	height: 100%;
	min-height: 3rem;
	display: flex;
	border-radius: 4px;
	border: solid thin rgba(55, 5, 104, 0.25);

	&__name {
		flex-grow: 1;
	}

	&__checkmark {
		flex-shrink: 0;
		width: 2rem;
		text-align: right;
	}
}
</style>
