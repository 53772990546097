<!--
this component is used in Pending Policy
-->
<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="[]"
        baseUrl=""
        :sticky="false"
        :has-demo="hasDemo"
        standard-submenu
        v-on="$listeners"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QMyClientsSubmenu",
    props: {
        hasDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "My Clients",
        }
    },
    computed: {
        buttons() {
            const buttons = [
                {
                    label: "Applications",
                    to: "/new_business/applications",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Placed Policies",
                    to: "/new_business/placed_policy",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Pending Policies",
                    to: "/new_business/pending_policy",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Conserved Policies",
                    to: "/new_business/conserved_polices/",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ]

            if (this.hasPermission('agent:pcs_permission')) {
                buttons.push({
                    label: "PCS Bonus",
                    to: "/new_business/commission/",
                    icon: "fas fa-arrow-alt-circle-right",
                });
            }

            buttons.push({
                label: "FIF Form",
                to: "/newbusiness/fif-form",
                icon: "fas fa-arrow-alt-circle-right",
            });
            buttons.push({
                label: "FIF Data",
                to: "/newbusiness/fif-form/data",
                icon: "fas fa-arrow-alt-circle-right",
            });

            return buttons;
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
